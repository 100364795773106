import { ReactElement, useState } from 'react';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import { Box, Grid, Typography } from '@mui/material';
import { getContrastTextColor } from '../../../utils/colorUtils';
import { AnalyticsEventColor } from '../../../store/cabinetApi/generated/analytics';
import { PROVIDER } from '../../../constants';
import colors from '../../../colors';

interface Props {
  categories: AnalyticsEventColor[];
  onSelect: (category: AnalyticsEventColor | null) => void;
  onCancel: () => void;
  show: boolean;
  defaultColor: string;
  provider?: number;
  onSyncColors?: () => Promise<void>;
}

export const AnalyticsEventColorPicker = ({
  categories, show, onSelect, onCancel, defaultColor, provider, onSyncColors
}: Props): ReactElement => {
  const [isSyncingColors, setIsSyncingColors] = useState(false);

  const categoriesWithDefault = [...categories, null];

  const handleSyncColors = async () => {
    if (onSyncColors) {
      setIsSyncingColors(true);
      await onSyncColors();
      setIsSyncingColors(false);
    }
  };

  const handleCancel = (): void => {
    onCancel();
  };

  return (
    <CabModal
      open={show}
      onClose={handleCancel}
      closeIcon={true}
      title={`Choose Color${provider === PROVIDER["MICROSOFT"].id ? " / Category" : ""}`}
      sx={{ '& .MuiPaper-root': { minWidth: 400, width: 500 } }}
      actionButtons={
        <Box display="flex" justifyContent={"space-between"} width="100%">
          <Box>
            {provider === PROVIDER["MICROSOFT"].id && 
              <CabButton buttonType="secondary" disabled={isSyncingColors} onClick={handleSyncColors}>
                {isSyncingColors ? "Sync In Progress..." : "Sync Colors from Outlook"}
              </CabButton>
            }
          </Box>
          <Box display="flex" gap={1}>
            <CabButton buttonType='secondary' onClick={handleCancel}>
              Cancel
            </CabButton>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={1}>
        {categoriesWithDefault.map(category => {
          const bgColor = category?.color || defaultColor;
          return <Grid 
            item 
            sm={provider === PROVIDER["MICROSOFT"].id ? 6 : 3} 
            key={`${category?.id}-${category?.color}`}
          >
            <Box 
              width="100%"
              minHeight={"32px"}
              height={provider === PROVIDER["MICROSOFT"].id ? "auto" : "32px"}
              borderRadius="8px"
              padding={"4px 8px"}
              sx={{
                border: `1px solid ${bgColor === defaultColor ? colors.black300 : defaultColor}`,
                background: bgColor,
                willChange: "transform",
                "&:hover": {
                  cursor: "pointer", 
                  transform: "scale(1.01)"
                },
              }}
              onClick={() => onSelect(category)}
            >
              <Typography 
                variant='body2'
                color={getContrastTextColor(bgColor)} 
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {provider === PROVIDER["MICROSOFT"].id
                  ? category?.category ? category.category : "No Category (Default Color)" 
                  : category ? "" : "Default Color"}
              </Typography>
            </Box>
          </Grid>;
        })}
      </Grid>
    </CabModal>
  );
};

export default AnalyticsEventColorPicker;