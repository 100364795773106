import { ReactElement, useMemo } from "react";
import { AnalyticsEventColor } from "../../../store/cabinetApi/generated/analytics";
import { actions, Calendar, RootState, ThunkDispatchType } from "../../../store";
import { PROVIDER, GOOGLE_COLORS } from "../../../constants";
import AnalyticsEventColorPicker from "./AnalyticsEventColorPicker";
import colors from "../../../colors";
import { useDispatch, useSelector } from "react-redux";
import { useMountEffect } from "../../../utils/hooks";
import { fetchAnalyticsEventColor } from "../../../store/eventAnalytics/actions";

interface Props {
  onSelect: (category: AnalyticsEventColor | null) => void;
  onCancel: () => void;
  show: boolean;
  calendar?: Calendar;
}

export const defaultAnalyticsEventColor: AnalyticsEventColor & {color: string} = {
  calendar: -1,
  organization: -1,
  color: colors.white900,
  category: null,
  event_category: null,
  event_type: null,
};

export const AnalyticsEventColorPickerContainer = ({
  show, onSelect, onCancel, calendar
}: Props): ReactElement => {

  const categories = useSelector((state: RootState) => state.eventAnalytics.analytics_colors);
  const organizationId = useSelector((state: RootState) => state.organization.id);
  const dispatch = useDispatch<ThunkDispatchType>();

  useMountEffect(() => {
    dispatch(fetchAnalyticsEventColor({}));
  });

  const handleSyncColors = async () => {
    await dispatch(actions.schedule.syncCalendarColors());
    await dispatch(fetchAnalyticsEventColor({}));
    return;
  };

  // MS categories must be selected from the available set, Google can just be a color from the list
  const pickableCategories = useMemo(() => {
    const existingCategories = categories.filter(category => category.calendar === calendar?.id);
    if (calendar?.provider === PROVIDER["GOOGLE"].id) {
      const pickableColors: {[color: string]: boolean} = {};
      existingCategories.forEach(cat => {
        if (cat.color) {
          pickableColors[cat.color.toLowerCase()] = true;
        }
      });
      Object.values(GOOGLE_COLORS.event).forEach(colorData => {
        const color = colorData['background'].toLowerCase();
        if (!pickableColors[color]) {
          const placeholderAnalyticsEventColor = {
            ...defaultAnalyticsEventColor,
            calendar: calendar?.id,
            color: color,
            organization: organizationId
          };
          existingCategories.push(placeholderAnalyticsEventColor);
          pickableColors[color] = true;
        }
      });
    }
    
    return existingCategories;
  }, [calendar?.id, calendar?.provider, categories, organizationId]);

  return <AnalyticsEventColorPicker
    show={show}
    categories={pickableCategories}
    onSelect={onSelect}
    onCancel={onCancel}
    defaultColor={defaultAnalyticsEventColor.color}
    provider={calendar?.provider}
    onSyncColors={handleSyncColors}
  />;
};

export default AnalyticsEventColorPickerContainer;